import Vue from 'vue'
import store from './store.js'
import router from './router.js'
import vuetify from './plugins/vuetify'; // Vue UI Bibliothek im Material Design
import App from './App.vue'

// observes if a component is resizing
import 'vue-resize/dist/vue-resize.css'
import VueResize from 'vue-resize'
Vue.use(VueResize)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

import axios from './axios.js'

import matomo from './matomo.js'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)

import VueFlipcard from 'vue-flipcard'
Vue.component('vue-flipcard', VueFlipcard)

new Vue({
  vuetify,
  store,
  axios,
  router,
  matomo,
  render: h => h(App)
}).$mount('#app')