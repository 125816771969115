<template>
  <div>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-text>
        <v-alert type="success" text>Sie wurden erfolgreich abgemeldet</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          id="nav-btn"
          color="primary"
          @click="routeToLogin"
          class="mx-auto"
        >
          <v-icon>mdi-login</v-icon>
          Zum Login
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/common/AppFooter";
export default {
  mounted: function() {
    this.$store.commit("user", null);
    this.$store.commit("falediaState", null);
    localStorage.clear();
  },
  components: {
    AppFooter,
  },
  methods: {
    routeToLogin() {
      this.$router.push("/");
    },
  },
};
</script>
