<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <v-alert v-if="notifyAboutEve !== undefined" type="info" text>
        <span v-if="notifyAboutEve == 'true'"
          >Vielen Dank, dass Sie an dem FALEDIA-Forschungsprojekt
          teilnehmen.</span
        >
        <span v-else
          >Schade, dass Sie nicht am Forschungsprojekt teilnehmen möchten. Beim
          nächsten Login fragen wir Sie noch einmal.</span
        >
      </v-alert>

      <h2 class="faledia-caption">Herzlich willkommen auf FALEDIA</h2>

      <p>
        FALEDIA ist eine <strong>fa</strong>llbasierte
        <strong>Le</strong>rnplattform zur Steigerung der
        <strong>Dia</strong>gnosekompetenz für die Lehrer:innenbildung
        Mathematik Primarstufe. Zu zentralen Inhalten ausgewählter Themen werden
        <i>Hintergrund</i> sowie <i>Diagnose und Förderung</i> dargestellt.
        <br />
        <br />
        Durch eine zielgerichtete Diagnose werden diejenigen Einblicke in das
        Denken und Handeln von Lernenden geliefert, die notwendig sind, um zu
        bestimmen, wo ein Kind im Lernprozess steht und wo angeknüpft werden
        kann. Außerdem können Fehlvorstellungen aufgedeckt werden.
        <br />
        <br />
        Diagnosefähigkeit zu entwickeln bedeutet, Produkte von Lernenden (z.B.
        Aussagen oder Bearbeitungen) daraufhin analysieren zu können, welche
        Kompetenzen bereits vorhanden sind, wo es Probleme gibt und welchen
        Ursachen diese Probleme zu Grunde liegen können. Die Auswahl passender
        Aufgaben zur Diagnose ist ebenfalls ein Aspekt der Diagnosefähigkeit.
        Damit Diagnose Wirkung zeigen kann, ist es erforderlich, dass eine
        passgenaue Förderung angeschlossen wird, die sich auf die Erkenntnisse
        der Diagnose stützt.
        <br />
        <br />
        Im Zusammenspiel von themenspezifischem fachlichem Hintergrundwissen und
        fachdidaktischem Wissen bietet FALEDIA sowohl Informationen als auch
        Übungen an, um die Diagnosefähigkeit für den jeweiligen ausgewählten
        Inhalt auf- und auszubauen.
        <br />
        <br />
        Wählen Sie über die Kärtchen das gewünschte Themengebiet aus.
      </p>

      <v-row dense>
        <v-col
          v-for="card in cards"
          :key="card.title"
          :xs="card.flex"
          :sm="card.flex + 2"
          :md="card.flex"
        >
          <v-card outlined :disabled="card.disabled" class="card">
            <div
              :class="card.class"
              style="padding: 10px; cursor: pointer; height: 100%"
              @click="navigateTo(card.start, true)"
            >
              <v-card-title
                class="white--text"
                v-text="card.title"
                style="justify-content: center"
              ></v-card-title>
              <v-img
                :src="require(`@/assets/${card.src}`)"
                class="align-end image"
                height="120px"
                width="120px"
              >
              </v-img>
            </div>
            <v-card-text v-if="card.text" class="text--primary text">
              <div>{{ card.text }}</div>
            </v-card-text>

            <v-card-actions class="action">
              <v-row dense>
                <v-col :cols="12" align="center">
                  <v-btn
                    small
                    text
                    style="font-weight: bold;"
                    @click="navigateTo(card.start, false)"
                    class="darkPetrol"
                  >
                    Einstieg
                  </v-btn>
                  <v-btn
                    small
                    text
                    style="font-weight: bold;"
                    class="darkPetrol"
                    @click="navigateTo(card.hintergrundwissen, false)"
                  >
                    Hintergrundwissen
                  </v-btn>
                  <v-btn
                    small
                    text
                    style="font-weight: bold;"
                    class="darkPetrol"
                    @click="navigateTo(card.diagnoseUndFoerderung, false)"
                  >
                    Diagnose & Förderung
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <br />
      <br />
      <!-- <ul>
      <li>E-mail: {{ user.email }}</li>
      <li>Einverstädnis Forschungsprojekt: {{ user.eve }}</li>
      <li>MamaPapaCode: {{ user.mamaPapaCode }}</li>
      <li>Rollen: {{ user.roles }}</li>
      <li>Abgeschlossene Umfragen: {{ user.completedSurveys }}</li>
    </ul> -->

      <v-divider style="margin-top: 8px;" />
      <br />
      <br />
      <p>
        Ein wichtiger Teil des FALEDIA Forschungsprojektes ist die Verbesserung
        der Lernplattform aufgrund Ihrer Meinung und Erfahrungen! Wenn Sie
        bereits Erfahrung mit der Lernplattform gemacht haben, würden wir uns
        freuen, wenn Sie noch ein paar Fragebögen beantworten würden.
      </p>
      <AppButtonCollapse
        icon="mdi-file-check-outline"
        messageHidden="Fragebögen anzeigen"
        messageNotHidden="Fragebögen verstecken"
        elevation="0"
      >
        <Survey
          v-if="showSurvey"
          assignedSite="home"
          :alreadySend="
            $store.state.user.completedSurveys
              ? $store.state.user.completedSurveys.includes('home')
              : false
          "
          :skipLearningStyle="false"
        />
      </AppButtonCollapse>

      <AppFooter />
    </v-card>
  </div>
</template>
<script>
import AppHelper from "@/common/AppHelper";
import AppFooter from "@/common/AppFooter";
import Survey from "@/components/faledia/Survey";
import AppButtonCollapse from "../common/AppButtonCollapse.vue";

export default {
  mounted() {
    let user = this.$store.state.user; // ToDo: this call seems to be necessary to get triggered reactively
    /*for (let roles of user.roles) {
      if (roles === "ov") {
        this.addOV();
        continue;
      } else if (roles === "swv") {
        this.addSWV();
        continue;
      } else if (roles === "survey") {
        this.showSurvey = true;
        continue;
      }
    }*/
    this.addZV();
    this.addOV();
    this.addEPE();
    this.addSWV();
    this.addNZR();
    this.addEME();
    this.addHR();
    this.addZR();
    this.showSurvey = true;
    if (user.roles.length <= 1) {
      // if we only have one topic we add that more topics are coming
      this.addMoreTopicsComming();
    }
    /* set matomo cookies after the user logged in */
    AppHelper.setMatomoCookies(this);

    window.addEventListener("beforeunload", (event) => {
      AppHelper.trackMatomoEvent(
        this,
        "App",
        "id:55; beforeunload event ausgelöst auf: " +
          event.target.baseURI +
          ";",
        "App"
      );
    });
  },
  components: {
    AppFooter,
    Survey,
    AppButtonCollapse,
  },
  methods: {
    // actually the 'to' attribute does exactly that, but there was a strange bug where the v-card-actions would be created two times.
    // to prevent this bug we do it this way
    navigateTo(url, clickedOnPicture) {
      this.$router.push({
        path: url,
      });
      if (clickedOnPicture) {
        AppHelper.trackMatomoEvent(
          this,
          "HomeCards",
          "id:59; Bild der Karte angeklickt: " + url + ";",
          "HomeCards"
        );
      } else {
        AppHelper.trackMatomoEvent(
          this,
          "HomeCards",
          "id:60; Text der Karte angeklickt: " + url + ";",
          "HomeCards"
        );
      }
    },
    addOV() {
      this.cards.push({
        title: "OPERATIONSVERSTÄNDNIS",
        src: "OV.svg",
        flex: 4,
        start: "/operationsverstaendnis",
        hintergrundwissen: "/operationsverstaendnis/hintergrundwissen",
        diagnoseUndFoerderung: "/operationsverstaendnis/uebergang",
        class: "colorOV", //Color is the same in every OV element
        // text:
        //   "Kurzer Text über das Thema, gerne auch in zwei Zeilen oder eventuell auch in drei, müsste man schauen von der Länge ...",
      });
    },
    addSWV() {
      this.cards.push({
        title: "STELLENWERTVERSTÄNDNIS",
        src: "SWV.svg",
        flex: 4,
        start: "/stellenwertverstaendnis",
        hintergrundwissen: "/stellenwertverstaendnis/hintergrundwissen",
        diagnoseUndFoerderung: "/stellenwertverstaendnis/uebergang",
        class: "colorSWV", //Color is the same in every Swv element
        // text:
        //   "Kurzer Text über das Thema, gerne auch in zwei Zeilen oder eventuell auch in drei, müsste man schauen von der Länge ...",
      });
    },
    addEPE() {
      this.cards.push({
        title: "EINSPLUSEINS",
        src: "EPE.svg",
        flex: 4,
        start: "/einspluseins",
        hintergrundwissen: "/einspluseins/hintergrundwissen",
        diagnoseUndFoerderung: "einspluseins/uebergang",
        class: "colorSWV",
      });
    },
    addZV() {
      this.cards.push({
        title: "ZAHLVERSTÄNDNIS",
        src: "ZV.svg",
        flex: 4,
        start: "/zahlverstaendnis",
        hintergrundwissen: "/zahlverstaendnis/hintergrundwissen",
        diagnoseUndFoerderung: "/zahlverstaendnis/uebergang",
        class: "colorSWV",
      });
    },
    addEME() {
      this.cards.push({
        title: "EINMALEINS",
        src: "EME.svg",
        flex: 4,
        start: "/einmaleins",
        hintergrundwissen: "/einmaleins/hintergrundwissen",
        diagnoseUndFoerderung: "/einmaleins/uebergang",
        class: "colorSWV",
      });
    },
    addNZR() {
      this.cards.push({
        title: "NICHT ZÄHLENDES RECHNEN",
        src: "NZR.svg",
        flex: 4,
        start: "/nicht-zaehlendes-rechnen",
        hintergrundwissen: "/nicht-zaehlendes-rechnen/hintergrundwissen",
        diagnoseUndFoerderung: "/nicht-zaehlendes-rechnen/uebergang",
        class: "colorSWV",
      });
    },
    addHR() {
      this.cards.push({
        title: "ZAHLENRECHNEN",
        src: "HR_w.svg",
        flex: 4,
        start: "/zahlenrechnen",
        hintergrundwissen: "/zahlenrechnen/hintergrundwissen",
        diagnoseUndFoerderung: "/zahlenrechnen/uebergang",
        class: "colorSWV",
      });
    },
    addZR() {
      this.cards.push({
        title: "ZIFFERNRECHNEN",
        src: "ZR_w.svg",
        flex: 4,
        start: "/ziffernrechnen",
        hintergrundwissen: "/ziffernrechnen/hintergrundwissen",
        diagnoseUndFoerderung: "/ziffernrechnen/uebergang",
        class: "colorOV", //Color is the same in every OV element
        // text:
        //   "Kurzer Text über das Thema, gerne auch in zwei Zeilen oder eventuell auch in drei, müsste man schauen von der Länge ...",
      });
    },
    addMoreTopicsComming() {
      this.cards.push({
        title: "Weitere Themen folgen...",
        src: "hammer-wrench.svg",
        flex: 4,
        disabled: true,
        class: "moreTopics",
        //text:
        //  "Weitere Themen sind in Bearbeitung und werden bald freigeschaltet",
      });
    },
  },
  data() {
    return {
      user: this.$store.state.user,
      notifyAboutEve: this.$route.params.notifyAboutEve, //null -> keine Benachrichtigung, true: nimmt teil, false: lehnt ab
      cards: [],
      showSurvey: false,
    };
  },
};
</script>
<style scoped>
.moreTopics {
  background-color: gray;
}
.card {
  margin-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.image {
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.text {
  height: 100%;
}
.action {
  height: auto;
}
</style>
