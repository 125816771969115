import Vue from 'vue'
import axios from "axios"; // promise based HTTP client for the browser and node.js

const base = axios.create({
    // baseURL: "http://localhost:4000"
    // baseURL: "http://192.168.178.45:4000"
    baseURL: "https://login.faledia.de"
});

Vue.prototype.$http = base; // Ersetzung Vue Standard Http Service durch Axios
Vue.config.productionTip = false

export default base;