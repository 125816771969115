import jwt_decode from "jwt-decode";
import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Home from "./components/Home.vue";

import Eve from "./components/auth/Eve.vue";
import store from "./store.js";
import axios from "./axios.js";
import Login from "./components/auth/Login.vue";
import Logout from "./components/auth/Logout.vue";
// import SWVI from './components/faledia/SWVI.vue';
// import SWVE from './components/faledia/SWVE.vue';
// import OVI from './components/faledia/OVI.vue';
// import OVE from './components/faledia/OVE.vue';

// import VueMatomo from './matomo.js'

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "unknown",
    component: Login,
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/eve",
    name: "eve",
    component: Eve,
    meta: {
      requiresAuth: true, // damit nur eingeloggte Personen diese Seite sehen können, ansonsten redirect zu /
      hideNavigation: true,
    },
  },
  {
    path: "/home/:notifyAboutEve?",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      requiresEve: true, // damit nur Personen die sich klar für oder gegen die Teilnahme am Forschungsprojekt ausgesprochen haben diese Seite sehen können ansonsten rediret zu /
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/swvi",
    name: "swvi",
    component: () => import("./components/faledia/seiten/swvi/SWVI.vue"),
    meta: {
      requiresAuth: true,
      requiresEve: true,
    },
  },
  {
    path: "/swvi/diagnose",
    name: "swvi_diagnose",
    component: () =>
      import("./components/faledia/seiten/swvi/SWVI_Diagnose.vue"),
    meta: {
      requiresAuth: true,
      requiresEve: true,
    },
  },
  {
    path: "/swve",
    name: "swve",
    component: () => import("./components/faledia/seiten/swve/SWVE.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true, // Zustand vom Server holen und wiederherstellen
      requiresEve: true,
    },
  },
  {
    path: "/swve/diagnose",
    name: "swve_diagnose",
    component: () =>
      import("./components/faledia/seiten/swve/SWVE_Diagnose.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ovi",
    name: "ovi",
    component: () => import("./components/faledia/seiten/ovi/OVI.vue"),
    meta: {
      requiresAuth: true,
      requiresEve: true,
    },
  },
  {
    path: "/ovi/diagnose",
    name: "ovi_diagnose",
    component: () => import("./components/faledia/seiten/ovi/OVI_Diagnose.vue"),
    meta: {
      requiresAuth: true,
      requiresEve: true,
    },
  },
  {
    path: "/ove",
    name: "ove",
    component: () => import("./components/faledia/seiten/ove/OVE.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ove/diagnose",
    name: "ove_diagnose",
    component: () => import("./components/faledia/seiten/ove/OVE_Diagnose.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("./Imprint.vue"),
    meta: {
      hideNavigationForLoggedOutUser: true,
    },
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () => import("./Accessibility.vue"),
    meta: {
      hideNavigationForLoggedOutUser: true,
    },
  },
  {
    path: "/operationsverstaendnis",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/Operationsverstaendnis.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/hintergrundwissen/grundvorstellungen",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/hintergrundwissen/Grundvorstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/hintergrundwissen/darstellungen",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/hintergrundwissen/Darstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/hintergrundwissen/aufgabenbeziehungen",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/hintergrundwissen/Aufgabenbeziehungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/operationsverstaendnis/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/operationsverstaendnis/check",
    component: () =>
      import("./components/faledia/seiten/operationsverstaendnis/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/Stellenwertverstaendnis.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/hintergrundwissen/BuendelnUndEntbuendeln.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/stellenwertverstaendnis/hintergrundwissen/stellenwert-und-zahlenwert",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/hintergrundwissen/StellenwertUndZahlenwert.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis/hintergrundwissen/darstellungswechsel",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/hintergrundwissen/Darstellungswechsel.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/stellenwertverstaendnis/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/stellenwertverstaendnis/check",
    component: () =>
      import("./components/faledia/seiten/stellenwertverstaendnis/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins",
    component: () =>
      import("./components/faledia/seiten/einsPlusEins/EinsPlusEins.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/einsPlusEins/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/hintergrundwissen/grundvorstellungen",
    component: () =>
      import(
        "./components/faledia/seiten/einsPlusEins/hintergrundwissen/Grundvorstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/hintergrundwissen/darstellungen",
    component: () =>
      import(
        "./components/faledia/seiten/einsPlusEins/hintergrundwissen/Darstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/hintergrundwissen/aufgabenbeziehungen",
    component: () =>
      import(
        "./components/faledia/seiten/einsPlusEins/hintergrundwissen/Aufgabenbeziehungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/einsPlusEins/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/einsPlusEins/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einspluseins/check",
    component: () =>
      import("./components/faledia/seiten/einsPlusEins/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/Zahlverstaendnis.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/hintergrundwissen/darstellungen",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/hintergrundwissen/Darstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/Hintergrundwissen/grundvorstellungen",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/hintergrundwissen/Grundvorstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/hintergrundwissen/zusammenhaenge",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/hintergrundwissen/Zusammenhaenge.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/zahlverstaendnis/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlverstaendnis/check",
    component: () =>
      import("./components/faledia/seiten/zahlverstaendnis/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins",
    component: () =>
      import("./components/faledia/seiten/einMalEins/EinMalEins.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/einMalEins/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/hintergrundwissen/kernaufgaben",
    component: () =>
      import(
        "./components/faledia/seiten/einMalEins/hintergrundwissen/Kernaufgabe.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/Hintergrundwissen/ableitungsstrategien",
    component: () =>
      import(
        "./components/faledia/seiten/einMalEins/hintergrundwissen/Ableitungsstrategien.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/hintergrundwissen/aufgaben-und-darstellungen-vernetzen",
    component: () =>
      import(
        "./components/faledia/seiten/einMalEins/hintergrundwissen/AufgabenUndDarstellungen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/einMalEins/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/einMalEins/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/einmaleins/check",
    component: () => import("./components/faledia/seiten/einMalEins/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/nicht-zaehlendes-rechnen",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/NichtZaehlendesRechnen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/nicht-zaehlendes-rechnen/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/nicht-zaehlendes-rechnen/hintergrundwissen/wieso-ist-zaehlendes-rechnen-problematisch",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/hintergrundwissen/RechnenProblem.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/nicht-zaehlendes-rechnen/Hintergrundwissen/teil-ganzes-verstaendnis",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/hintergrundwissen/TeilGanzes.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/nicht-zaehlendes-rechnen/hintergrundwissen/aufbau-flexibler-rechenstrategien",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/hintergrundwissen/AufbauRechenstrategien.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/nicht-zaehlendes-rechnen/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/nicht-zaehlendes-rechnen/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/nichtZaehlendesRechnen/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/nicht-zaehlendes-rechnen/check",
    component: () =>
      import("./components/faledia/seiten/nichtZaehlendesRechnen/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlenrechnen",
    component: () =>
      import("./components/faledia/seiten/zahlenrechnen/Zahlenrechnen.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlenrechnen/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/zahlenrechnen/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/zahlenrechnen/hintergrundwissen/strategien-des-halbschriftlichen-rechnens",
    component: () =>
      import(
        "./components/faledia/seiten/zahlenrechnen/hintergrundwissen/StrategienHR.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/zahlenrechnen/hintergrundwissen/flexibler-einsatz-von-rechenstrategien",
    component: () =>
      import(
        "./components/faledia/seiten/zahlenrechnen/hintergrundwissen/FlexiblerEinsatzRechenstrategien.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path:
      "/zahlenrechnen/hintergrundwissen/darstellungsweisen-beim-halbschriftlichen-rechnen",
    component: () =>
      import(
        "./components/faledia/seiten/zahlenrechnen/hintergrundwissen/DarstellungsweisenHR.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlenrechnen/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/zahlenrechnen/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlenrechnen/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/zahlenrechnen/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/zahlenrechnen/check",
    component: () =>
      import("./components/faledia/seiten/zahlenrechnen/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen",
    component: () =>
      import("./components/faledia/seiten/ziffernrechnen/Ziffernrechnen.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/hintergrundwissen",
    component: () =>
      import(
        "./components/faledia/seiten/ziffernrechnen/hintergrundwissen/Hintergrundwissen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/hintergrundwissen/verfahrensweisen",
    component: () =>
      import(
        "./components/faledia/seiten/ziffernrechnen/hintergrundwissen/Verfahrensweisen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/hintergrundwissen/verstehensorientierte-erarbeitung",
    component: () =>
      import(
        "./components/faledia/seiten/ziffernrechnen/hintergrundwissen/Verstehensorientiert.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/hintergrundwissen/flexibles-rechnen",
    component: () =>
      import(
        "./components/faledia/seiten/ziffernrechnen/hintergrundwissen/FlexiblesRechnen.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/uebergang",
    component: () =>
      import(
        "./components/faledia/seiten/ziffernrechnen/diagnoseUndFoerderung/Uebergang.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/diagnose-und-foerderung",
    component: () =>
      import(
        "./components/faledia/seiten/ziffernrechnen/diagnoseUndFoerderung/DiagnoseUndFoerderung.vue"
      ),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/ziffernrechnen/check",
    component: () =>
      import("./components/faledia/seiten/ziffernrechnen/Check.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/testseite",
    component: () => import("./components/faledia/seiten/TestSeite.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
  {
    path: "/tabeastestseite",
    component: () => import("./components/faledia/seiten/TabeasTest.vue"),
    meta: {
      requiresAuth: true,
      restoreData: true,
      requiresEve: true,
    },
  },
];

const router = new VueRouter({
  mode: "history", // looks like typical url
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo, {
      duration: 0,
    });
  },
  routes,
});

function clearStoreLocalStorageAndRedirect() {
  store.commit("user", null);
  store.commit("falediaState", null);
  localStorage.clear();
  // next({
  //   path: "/"
  // });
  router.push({
    path: "/",
  });
}

router.beforeEach(async function(to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = localStorage.getItem("jwt");
    if (token == null) {
      // we have no token, so we have to login first
      clearStoreLocalStorageAndRedirect();
      return;
    } else {
      // ToDo: We have a problem here. After a login we get a token.
      // Actuall calls that need a token are /eve and /survey
      // after those calls were made, we get a new token
      // if we have an expired token those calls would not be possible
      // locally we check if the token is expired and redirect to /
      // we should do this against the server, but i want to save calls ...

      const decoded_token = jwt_decode(token);

      if (new Date(decoded_token.exp * 1000) < new Date()) {
        // the token is expired, we send the person to /
        // store.commit("user", null);
        // store.commit("falediaState", null);
        // localStorage.clear();
        // next({
        //   path: "/"
        // });
        clearStoreLocalStorageAndRedirect();
        return;
      } else {
        // the token is not expired
        if (to.matched.some((record) => record.meta.requiresEve)) {
          if (decoded_token.eve == null) {
            // the person didn't accepted or declined the participation so we redirect to /
            // store.commit("user", null);
            // store.commit("falediaState", null);
            // localStorage.clear();
            // next({
            //   path: "/"
            // });
            clearStoreLocalStorageAndRedirect();
            return;
          }
        }
        // https://vuex.vuejs.org/guide/mutations.html
        // https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/
        store.commit("user", decoded_token);

        // console.log(VueMatomo.hasConsent());

        if (to.matched.some((record) => record.meta.restoreData)) {
          // we have to ask the server for the state and we also get a new token for free ;)
          // we do this call everytime, this ensures that the users always has the newest state no matter what he or she is doing
          if (store.state.falediaState == null) {
            try {
              await axios
                .get("/user/state", {
                  headers: {
                    Authorization: token,
                  },
                })
                .then((response) => {
                  localStorage.setItem("jwt", response.data.token);
                  store.commit("user", jwt_decode(response.data.token)); // we get a new token, why not use it?
                  const falediaState = response.data.state;
                  if (falediaState != null) {
                    // console.log("got state from server");
                    store.commit("falediaState", falediaState); // we just can save the state in our store, localstorage is only key value
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (err) {
              console.log(err);
            }
          }
        }
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
