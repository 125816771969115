<template>
  <!-- footer -->
  <v-bottom-navigation
    horizontal
    fixed
    height="auto"
    style="padding-left: 60px; padding-top: 5px; padding-bottom: 5px;"
  >
    <!-- -->
    <v-row no-gutters>
      <v-col cols="6">
        <div class="text-left" style="font-size: small;">
          <router-link
            to="/imprint"
            style="margin-right: 10px; margin-left: 10px;"
            class="footer-link"
            >Impressum</router-link
          >
          <router-link
            to="/accessibility"
            style="margin-right: 10px;"
            class="footer-link"
            >Barrierefreiheit</router-link
          >
        </div>
      </v-col>
      <v-col cols="6" align-self="center">
        <div
          class="text-right"
          style="padding-right: 8px; font-size: small; color: gray"
        >
          <v-icon small>mdi-copyright</v-icon>
          FALEDIA {{ year }}
        </div>
      </v-col>
    </v-row>
  </v-bottom-navigation>
</template>

<script>
export default {
  computed: {
    year: function() {
      return 1900 + new Date().getYear();
    },
  },
};
</script>

<style>
#app a.footer-link {
  list-style-type: none;
  text-decoration: none;
  color: gray;
}
#app a.footer-link:hover,
a.footer-link.router-link-active,
a.footer-link.router-link-exact-active {
  color: #f26721;
  cursor: pointer;
}
</style>
