<template>
  <v-col cols="12">
    <v-radio-group
      class="moreCompact"
      :class="[{ dense: dense }]"
      :rules="[this.value != null || '']"
      dense
      row
      v-model="value"
      @change="logChangeEvent"
    >
      <!-- class="justify-center" -->
      <div
        style="width: 98px; margin-right: 0px"
        :class="[{ 'error--text': this.value == null ? !valid : false }]"
      >
        {{ left }}
      </div>
      <v-radio v-for="n in circleCount" :key="n" :value="n" />
      {{ right }}
    </v-radio-group>
  </v-col>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  props: {
    id: String,
    left: String,
    right: String,
    circleCount: Number,
    dense: Boolean,
    valid: Boolean,
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getData() {
      let object = {};
      object["id"] = this.id;
      object["scala"] = this.left + "/" + this.right;
      object["value"] = this.value;
      return object;
    },

    logChangeEvent() {
      AppHelper.trackMatomoEvent(
        this,
        "Survey",
        "id:50; Value: " +
          this.value +
          "; Scala: " +
          this.left +
          "/" +
          this.right +
          ";",
        this.id
      );
    },
  },
};
</script>
<style scoped>
* {
  color: black;
}
.moreCompact {
  margin-top: -12px;
  padding-top: 0px;
}
.dense {
  margin-bottom: -25px;
}
</style>
