<template>
  <div>
    <!-- class="justify-center" -->
    <v-radio-group
      class="moreCompact"
      :rules="[this.value != null || '']"
      dense
      column
      v-model="value"
      @change="logChangeEvent"
    >
      {{ question }}
      <v-radio :value="answerACategory">
        <template v-slot:label>
          <div>{{ answerA }}</div>
        </template>
      </v-radio>
      <v-radio :value="answerBCategory" :label="answerB">
        <template v-slot:label>
          <div>{{ answerB }}</div>
        </template></v-radio
      >
    </v-radio-group>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  props: {
    id: String,

    question: String,
    answerA: String,
    answerACategory: String,

    answerB: String,
    answerBCategory: String,
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getData() {
      let object = {};
      object["id"] = this.id;
      object["value"] = this.value;
      return object;
    },
    logChangeEvent() {
      AppHelper.trackMatomoEvent(
        this,
        "Survey",
        "id:51; Value: " + this.value + ";",
        this.id
      );
    },
  },
};
</script>
<style scoped>
* {
  color: black;
}
.moreCompact {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
