<template>
  <v-col cols="12" style="margin-bottom: 5px;">
    <p
      style="hyphens: auto; 
	text-align: justify;"
    >
      {{ description }}
    </p>

    <v-row justify="center">
      <v-radio-group
        class="moreCompact"
        :rules="[this.value != null || '']"
        dense
        row
        v-model="value"
        @change="logChangeEvent"
      >
        <!-- class="justify-center" -->
        <div
          style="margin-right: 15px;"
          :class="[{ 'error--text': this.value == null ? !valid : false }]"
        >
          Stimme nicht zu
        </div>
        <v-radio v-for="n in circleCount" :key="n" :value="n" />
        Stimme zu
      </v-radio-group>
    </v-row>
  </v-col>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  props: {
    id: String,
    circleCount: Number,
    description: { String, required: true },
    valid: Boolean,
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getData() {
      let object = {};
      object["id"] = this.id;
      object["scala"] = "Stimme nicht zu/Stimme zu";
      object["value"] = this.value;
      return object;
    },
    logChangeEvent() {
      AppHelper.trackMatomoEvent(
        this,
        "Survey",
        "id:50; Value: " + this.value + "; Scala: Stimme nicht zu/Stimme zu;",
        this.id
      );
    },
  },
};
</script>
<style scoped>
* {
  color: black;
}
.moreCompact {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
