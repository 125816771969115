<template>
  <v-col cols="12" align="center">
    <h4>{{ text }}</h4>
    <p
      style="hyphens: auto; 
	text-align: justify;
  max-width: 60%;"
    >
      {{ description }}
    </p>

    <v-row justify="center">
      <v-radio-group
        class="moreCompact"
        :rules="[this.value != null || '']"
        dense
        row
        v-model="value"
        @change="logChangeEvent"
      >
        <!-- class="justify-center" -->
        <div
          style="width: 45px; margin-right: 0px"
          :class="[{ 'error--text': this.value == null ? !valid : false }]"
        >
          {{ left }}
        </div>
        <v-radio
          v-for="(n, index) in circleCount"
          :key="n"
          :value="n"
          style="margin-right: 0px;   justify-content: center;"
          :label="index + 1 == borderAfter ? '|' : ''"
          :id="'v-radio'"
          >{{ index }} test</v-radio
        >
        {{ right }}
      </v-radio-group>
    </v-row>
  </v-col>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  props: {
    id: String,
    left: String,
    right: String,
    circleCount: Number,
    text: String,
    borderAfter: Number,
    description: { String, required: true },
    valid: Boolean,
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    getData() {
      let object = {};
      object["id"] = this.id;
      object["scala"] = this.left + "/" + this.right;
      object["value"] = this.value;
      return object;
    },
    logChangeEvent() {
      AppHelper.trackMatomoEvent(
        this,
        "Survey",
        "id:50; Value: " +
          this.value +
          "; Scala: " +
          this.left +
          "/" +
          this.right +
          ";",
        this.id
      );
    },
  },
};
</script>
<style scoped>
* {
  color: black;
}
.moreCompact {
  margin-top: 0px;
  padding-top: 0px;
}

#v-radio label {
  margin-left: -8px;
}
</style>
