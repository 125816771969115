import store from '../store.js'
import router from '../router.js'

export default {
    isUserAllowedRedirectIfNot: function (role) {
        if (
            store.state.user &&
            !store.state.user.roles.includes(role)
        ) {
            // if we have no user OR
            // we have not the necessary role we go home ;)
            router.push("/home");
        }
    },

    setMatomoCookies(vue) {
        if (store.state.user && store.state.user.eve) {
            /* if we refresh the site, we have to set the cookies again */
            vue.$matomo && vue.$matomo.setUserId(store.state.user.email);
            vue.$matomo && vue.$matomo.setConsentGiven();
        }
    },

    trackMatomoEvent(vue, category, action, name) {
        this.setMatomoCookies(vue);
        let matomoEventCounter = '; matomoEventCouter: ' + store.state.matomoEventCounter;
        vue.$matomo.trackEvent(
            category,
            action,
            name + matomoEventCounter
        );
        store.commit('incrementMatomoEventCounter');
    },

    isSafari() {
        return (
            navigator.vendor &&
            navigator.vendor.indexOf("Apple") > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf("CriOS") == -1 &&
            navigator.userAgent.indexOf("FxiOS") == -1
        );
    },
    videoPlayerEvent: function (vue, event, id) {
        let local_id = 0;
        if (event.type == "play") {
            local_id = 40;
        } else if (event.type == "pause") {
            local_id = 41;
        } else if (event.type == "ended") {
            local_id = 42;
        } else if (event.type == "seeking") {
            local_id = 43;
        } else if (
            event.type == "fullscreenchange" ||
            event.type == "webkitfullscreenchange" ||
            event.type == "mozfullscreenchange" ||
            event.type == "msfullscreenchange"
        ) {
            local_id = 44;
        } else if (event.type == "enterpictureinpicture") {
            local_id = 45;
        } else if (event.type == "leavepictureinpicture") {
            local_id = 46;
        }
        this.trackMatomoEvent(
            vue,
            "SWVI",
            "id:" +
            local_id +
            "; Video " +
            event.type +
            " currentTime: " +
            event.currentTarget.currentTime +
            " duration: " +
            event.currentTarget.duration +
            ";",
            id
        );
    },
    audioEnded: function (vue, id) {
        this.trackMatomoEvent(
            vue,
            "SWVI",
            "id:33; Audio komplett abgespielt;",
            id
        );
    },
    audioPlayerClicked: function (vue, event, id, ref) {
        if (
            ref.firstPlay &&
            !event.srcElement.classList.contains("v-card__text")
        ) {
            // other than in LernbausteinStempeln a click on the surrounding vcard can trigger an event
            // the first playback can cause an unwated pause event, so we have to check it this way
            this.trackMatomoEvent(
                vue,
                "SWVI",
                "id:32; Audio first play currentTime: " +
                ref.currentTime +
                " duration: " +
                ref.duration +
                ";",
                id
            );
        } else {
            if (event.srcElement.classList.contains("mdi-stop")) {
                this.trackMatomoEvent(
                    vue,
                    "SWVI",
                    "id:34; Audio stop currentTime: " +
                    ref.currentTime +
                    " duration: " +
                    ref.duration +
                    ";",
                    id
                );
            } else if (event.srcElement.classList.contains("mdi-play")) {
                // der button hat noch das andere logo, es handelt sich aber um pause
                this.trackMatomoEvent(
                    vue,
                    "SWVI",
                    "id:35; Audio pause currentTime: " +
                    ref.currentTime +
                    " duration: " +
                    ref.duration +
                    ";",
                    id
                );
            } else if (event.srcElement.classList.contains("mdi-pause")) {
                // der button hat noch das andere logo, es handelt sich aber um play
                this.trackMatomoEvent(
                    vue,
                    "SWVI",
                    "id:36; Audio play currentTime: " +
                    ref.currentTime +
                    " duration: " +
                    ref.duration +
                    ";",
                    id
                );
            } else if (event.srcElement.classList.contains("mdi-volume-mute")) {
                this.trackMatomoEvent(
                    vue,
                    "SWVI",
                    "id:37; Audio mute currentTime: " +
                    ref.currentTime +
                    " duration: " +
                    ref.duration +
                    ";",
                    id
                );
            } else if (event.srcElement.classList.contains("mdi-volume-high")) {
                this.trackMatomoEvent(
                    vue,
                    "SWVI",
                    "id:38; Audio unmute currentTime: " +
                    ref.currentTime +
                    " duration: " +
                    ref.duration +
                    ";",
                    id
                );
            }
        }
    },
};