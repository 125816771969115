<style scoped>
.v-text-field {
  width: 200px;
}
#mpc-info {
  display: inline;
}
</style>

<template>
  <v-card width="900" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display-1">Einverständniserklärung</h1>
    </v-card-title>
    <v-card-text class="text--primary">
      <p>
        Hiermit willige ich ein, dass meine Daten wie im
        <a @click="showDatenschutzPDF">beigefügten PDF</a> beschrieben in dem
        Verfahren/ dem Projekt "Nachverfolgung des Nutzungsverhalten einer
        webbasierten Lernplattform durch Speichern der Benutzerinteraktionen"
        (mit Hilfe von Cookies) erhoben, gespeichert und verwendet werden. Diese
        Einwilligung kann ich jederzeit ohne Angabe von Gründen widerrufen,
        weiterhin kann ich Auskunft über die gespeicherten Daten und
        Berichtigung, Löschung und Sperrung meiner personenbezogenen Daten
        verlangen.
      </p>
      <v-form id="eve-form" ref="form" v-model="valid" @submit.prevent="setEve">
        <v-checkbox
          v-model="eve.isDsgvo"
          label="Ich bin mit der Datenschutzerklärung einverstanden"
        ></v-checkbox>
        <p>
          Hiermit bestätige ich, dass ich als Versuchsperson an einer
          Laborstudie des FALEDIA-Projekts wie im
          <a @click="showTeilnahmePDF">beigefügten PDF</a> beschrieben
          teilnehme. Meine Teilnahme erfolgt freiwillig.
        </p>
        <v-checkbox
          v-model="eve.isEve"
          label="Ich bin mit der Teilnahme am FALEDIA Forschungsprojekt und der Aufzeichnung meines Benutzungsverhalten einverstanden"
        ></v-checkbox>
        <!-- MamaPapaCode wird aktuell nicht benötigt -->
        <!-- <v-expand-transition>
          <v-row v-if="eve.isEve">
            <v-col cols="4">
              <v-text-field
                v-bind:required="eve.isEve"
                label="MamaPapaCode"
                filled
                v-model="eve.mpCode"
                :counter="6"
                :rules="mpcRules"
                dense
                class="ml-auto mr-2"
              ></v-text-field>
            </v-col>
            <v-col cols="7">
              <p id="mpc-info">
                Bitte gib deinen MamaPapaCode, bestehend aus den ersten zwei
                Buchstaben des Namens deiner Mutter, den ersten 2 Buchstaben des
                Namens deines Vaters und deinen Geburts
                <em>tag</em> ein.
              </p>
            </v-col>
          </v-row>
        </v-expand-transition> -->
      </v-form>
      <v-alert type="error" text v-if="err">{{ err }}</v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        id="btn_submit"
        :disabled="!valid"
        @click="validate"
        form="eve-form"
        type="submit"
        color="primary"
        class="mx-auto"
        >Weiter</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      eve: {
        isEve:
          this.$store.state.user.eve != null
            ? this.$store.state.user.eve
            : false,
        isDsgvo: false || this.$store.state.user.eve != null ? true : false,
        mpCode: "",
      },
      err: "",
      mpcRules: [
        (v) => !!v || "MamaPapaCode wird benötigt",
        (v) => v.length == 6 || "MamaPapaCode muss genau 6 Zeichen lang sein",
        (v) =>
          /\D{4}\d{2}/.test(v) ||
          "MamaPapaCode muss aus vier Buchstaben und anschließenden zwei Ziffern bestehen!",
      ],
      valid: true,
      eveDatenschutzUrl: "/assets/Einwilligungsschreiben_Datenschutz.pdf",
      eveTeilnahmeUrl:
        "/assets/Einverständniserklärung_Teilnahme_Forschungsprojekt_Ergänzung Interview.pdf",
      eveText:
        "Hiermit willige ich ein, dass meine Daten wie im beigefügten PDF. beschrieben in dem Verfahren/ dem Projekt Nachverfolgung des Nutzungsverhalten einer webbasierten Lernplattform durch Speichern der Benutzerinteraktionen erhoben, gespeichert und verwendet werden. Diese Einwilligung kann ich jederzeit ohne Angabe von Gründen widerrufen, weiterhin kann ich Auskunft über die gespeicherten Daten und Berichtigung, Löschung und Sperrung meiner personenbezogenen Daten verlangen.",
    };
  },
  methods: {
    async setEve() {
      try {
        const token = localStorage.getItem("jwt");
        const isEve = this.eve.isEve;

        if (token) {
          if (this.eve.isDsgvo) {
            try {
              await this.$http
                .post("/user/eve", this.eve, {
                  headers: {
                    Authorization: token,
                  },
                })
                .then((response) => {
                  localStorage.setItem("jwt", response.data.token);
                  this.$router.push("/home/" + isEve);
                })
                .catch((err) => {
                  this.err = err;
                });
            } catch (err) {
              this.err = err;
            }
          } else {
            this.err =
              "Um die Plattform benutzen zu können muss die Datenschutzerklärung angenommen werden.";
          }
        } else {
          this.$router.push("/");
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    showDatenschutzPDF() {
      window.open(this.eveDatenschutzUrl);
    },
    showTeilnahmePDF() {
      window.open(this.eveTeilnahmeUrl);
    },
  },
};
</script>
