<template>
  <v-card light v-if="showResendForm">
    <v-card-title>
      <h1 class="display-1">Passwort vergessen?</h1>
    </v-card-title>

    <v-card-text>
      <p>
        Geben Sie Ihre E-Mail Adresse ein und Sie bekommen ein neues Passwort zugeschickt.
      </p>
      <v-form
        id="resend-password-form"
        v-model="valid"
        @submit.prevent="resendPassword"
      >
        <v-text-field
          label="E-Mail"
          prepend-icon="mdi-account-circle"
          :rules="emailRules"
          v-model="user.email"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn @click="close">Abbrechen</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        type="submit"
        color="primary"
        form="resend-password-form"
        :disabled="!valid"
        >Anfragen</v-btn
      >
    </v-card-actions>
  </v-card>
  <alertcard
    v-else
    title="Zugangsdaten anfordern"
    :type="err ? 'error' : 'success'"
    :message="err ? 'Es ist ein Fehler aufgetreten. Haben Sie einen Account unter dieser E-Mail?' : success"
    :width="300"
  />
</template>

<script>
import eventBus from "../../common/EventBus";
import alertcard from "../../common/AppAlertCard";

export default {
  components: {
    alertcard,
  },

  data() {
    return {
      emailRules: [
        (v) => !!v || "E-Mail wird benötigt",
        (v) => /.+@.+\..+/.test(v) || "E-Mail muss valide sein",
      ],
      valid: true,
      user: {
        email: "",
      },
      success: "Sie erhalten in Kürze eine E-Mail mit Ihren Zugangsdaten",
      err: "",
      showResendForm: true,
    };
  },

  methods: {
    async resendPassword() {
      try {
        await this.$http
          .put("/user/createNewPassword", this.user)
          .then(() => {
            this.showResendForm = false;
          })
          .catch((err) => {
            this.err = err.response.status + ": " + err.response.data.err;
            this.showResendForm = false;
          });
        // TODO
      } catch (err) {
        console.log(err.response);
      }
    },

    close() {
      eventBus.$emit("close");
    },
  },

  created() {
    eventBus.$on("alertcard-close", this.close);
  },
};
</script>
