<template>
  <div>
    <v-btn
      block
      outlined
      text
      color="primary"
      :elevation="elevation"
      :disabled="!ableToCollapseLocally"
      @click="changeState(true)"
    >
      <v-icon style="margin-inline-end: 5px">{{ icon }}</v-icon>
      {{ hidden ? messageHidden : messageNotHidden }}
      <v-icon class="icon">{{
        !hidden ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </v-btn>
    <div v-if="hidden" :style="{ 'margin-bottom': bottomMargin + 'px' }"></div>
    <v-expand-transition>
      <div v-show="!hidden" class="mt-2">
        <slot></slot>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import eventBus from "@/common/EventBus";
import AppHelper from "@/common/AppHelper";
export default {
  name: "AppButtonCollapse",
  props: {
    messageHidden: { String, default: "Weitere Übung anzeigen" },
    messageNotHidden: { String, default: "Weitere Übung verstecken" },
    icon: { String, default: "mdi-text-box-multiple-outline" },
    elevation: { Number, default: 0 },
    collapseId: String,
  },
  data: () => ({
    hidden: true,
    ableToCollapseLocally: true,
  }),
  methods: {
    changeState(userInteraction) {
      if (this.ableToCollapseLocally) {
        this.hidden = !this.hidden;
        AppHelper.trackMatomoEvent(
          this,
          "AppButtonCollapse",
          "id:61; Status offen: " +
            !this.hidden +
            "; Benutzerinteraktion: " +
            userInteraction +
            "; Text: " +
            this.messageHidden +
            "; collapseId: " +
            this.collapseId +
            ";",
          "AppButtonCollapse"
        );
      }
    },
    activateCollapse() {
      this.ableToCollapseLocally = true;
    },
    deactivateCollapse() {
      this.hidden = true;
      this.ableToCollapseLocally = false;
    },
  },
  computed: {
    bottomMargin: function() {
      return this.collapseId ? 0 : 20;
    },
  },
  created() {
    if (this.collapseId != null) {
      this.ableToCollapseLocally = false;
      eventBus.$on(this.collapseId, this.activateCollapse);
      eventBus.$on(this.collapseId + "Reset", this.deactivateCollapse);
      eventBus.$on(this.collapseId + "Open", this.changeState);
    }
  },
};
</script>
