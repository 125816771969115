<template>
  <div @mouseenter="mini = false" @mouseleave="mini = true">
    <sidebar-menu
      theme="white-theme"
      :menu="menu"
      :collapsed="mini"
      v-click-outside="onClickOutside"
      @toggle-collapse="onToggleCollapse"
      showOneChild
      disableHover
      @item-click="onClickInside"
      widthCollapsed="60px"
      hideToggle
    >
      <div slot="header" @click="onClickHeader" class="navigationHeader">
        <v-icon v-if="mini">mdi-backburger mdi-rotate-180</v-icon>
        <v-icon v-else>mdi-backburger</v-icon>
      </div>
      <!-- <div slot="footer">footer</div> -->
      <!-- <span slot="toggle-icon">
        <v-icon v-if="mini">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </span> -->
      <span slot="dropdown-icon"><v-icon>mdi-chevron-right</v-icon></span>
    </sidebar-menu>
  </div>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import AppHelper from "@/common/AppHelper";
export default {
  components: {
    SidebarMenu,
  },
  mounted: function() {
    //let user = this.$store.state.user; // ToDo: this call seems to be necessary to get triggered reactively
    /*for (let roles of user.roles) {
      if (roles === "ov") {
        this.addMenuOV();
        continue;
      } else if (roles === "swv") {
        this.addMenuSWV();
        continue;
      }
    }*/
    this.addMenuZV();
    this.addMenuOV();
    this.addMenuEPE();
    this.addMenuSWV();
    this.addMenuNZR();
    this.addMenuEME();
    this.addMenuHR();
    this.addMenuZR();
    this.addMenuLogout();
  },
  methods: {
    onClickHeader() {
      this.mini = !this.mini;
    },
    onClickInside(event) {
      if (this.mini) {
        this.mini = !this.mini;
      }
      AppHelper.trackMatomoEvent(
        this,
        "AppNavLeft",
        "id:80; Item angeklickt; pathname: " +
          event.currentTarget.pathname +
          ";",
        "AppNavLeft"
      );
    },
    onClickOutside() {
      this.mini = true;
    },
    onToggleCollapse(collapse) {
      this.mini = collapse;
    },

    addMenuZR() {
      this.menu.push(
        {
          href: "/ziffernrechnen",
          title: "Ziffernrechnen",
          icon: "ZR",
          class: "IconColor",
          child: [
            {
              href: "/ziffernrechnen/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href: "/ziffernrechnen/hintergrundwissen/verfahrensweisen",
                  title: "Verfahrensweisen",
                },
                {
                  href:
                    "/ziffernrechnen/hintergrundwissen/verstehensorientierte-erarbeitung",
                  title: "Verstehensorientierte Erarbeitung",
                },
                {
                  href: "/ziffernrechnen/hintergrundwissen/flexibles-rechnen",
                  title: "Flexibles Rechnen",
                },
              ],
            },
            {
              href: "/ziffernrechnen/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/ziffernrechnen/diagnose-und-foerderung",
                  title: "Diagnose und Förderung",
                },
              ],
            },
            {
              href: "/ziffernrechnen/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },

    addMenuNZR() {
      this.menu.push(
        {
          href: "/nicht-zaehlendes-rechnen",
          title: "Nicht-zählendes Rechnen",
          icon: "mdi mdi-braille",
          class: "IconColor",
          child: [
            {
              href: "/nicht-zaehlendes-rechnen/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href:
                    "/nicht-zaehlendes-rechnen/hintergrundwissen/wieso-ist-zaehlendes-rechnen-problematisch",
                  title: "Wieso ist Zählendes Rechnen problematisch",
                },
                {
                  href:
                    "/nicht-zaehlendes-rechnen/hintergrundwissen/teil-ganzes-verstaendnis",
                  title: "Teil-Ganzes-Verständnis",
                },
                {
                  href:
                    "/nicht-zaehlendes-rechnen/hintergrundwissen/aufbau-flexibler-rechenstrategien",
                  title: "Aufbau flexibler Rechenstrategien",
                },
              ],
            },
            {
              href: "/nicht-zaehlendes-rechnen/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/nicht-zaehlendes-rechnen/diagnose-und-foerderung",
                  title: "Diagnose und Förderung",
                },
              ],
            },
            {
              href: "/nicht-zaehlendes-rechnen/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
    addMenuEME() {
      this.menu.push(
        {
          href: "/einmaleins",
          title: "Einmaleins",
          icon: "mdi mdi-circle-small",
          class: "IconColor",
          child: [
            {
              href: "/einmaleins/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href: "/einmaleins/hintergrundwissen/kernaufgaben",
                  title: "Kernaufgaben",
                },
                {
                  href: "/einmaleins/hintergrundwissen/ableitungsstrategien",
                  title: "Ableitungsstrategien",
                },
                {
                  href:
                    "/einmaleins/hintergrundwissen/aufgaben-und-darstellungen-vernetzen",
                  title: "Aufgaben und Darstellungen vernetzen",
                },
              ],
            },
            {
              href: "/einmaleins/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/einmaleins/diagnose-und-foerderung",
                  title: "Diagnose und Förderung",
                },
              ],
            },
            {
              href: "/einmaleins/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
    addMenuZV() {
      this.menu.push(
        {
          href: "/zahlverstaendnis",
          title: "Zahlverständnis",
          icon: "mdi mdi-numeric",
          class: "IconColor",
          child: [
            {
              href: "/zahlverstaendnis/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href:
                    "/zahlverstaendnis/hintergrundwissen/grundvorstellungen",
                  title: "Grundvorstellungen von Zahlen",
                },
                {
                  href: "/zahlverstaendnis/hintergrundwissen/darstellungen",
                  title: "Darstellungen vernetzen",
                },
                {
                  href: "/zahlverstaendnis/hintergrundwissen/zusammenhaenge",
                  title: "Zusammenhänge zwischen Zahlen",
                },
              ],
            },
            {
              href: "/zahlverstaendnis/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/zahlverstaendnis/diagnose-und-foerderung",
                  title: "Diagnose und Förderung",
                },
              ],
            },
            {
              href: "/zahlverstaendnis/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
    addMenuEPE() {
      this.menu.push(
        {
          href: "/einspluseins",
          title: "Einspluseins",
          icon: "mdi mdi-plus-thick",
          class: "IconColor",
          child: [
            {
              href: "/einspluseins/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href: "/einspluseins/hintergrundwissen/grundvorstellungen",
                  title: "Grundvorstellungen der Addition",
                },
                {
                  href: "/einspluseins/hintergrundwissen/aufgabenbeziehungen",
                  title: "Aufgabenbeziehungen nutzen",
                },
                {
                  href: "/einspluseins/hintergrundwissen/darstellungen",
                  title: "Darstellungen vernetzen",
                },
              ],
            },
            {
              href: "/einspluseins/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/einspluseins/diagnose-und-foerderung",
                  title: "Diagnose und Förderung",
                },
              ],
            },
            {
              href: "/einspluseins/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
    addMenuOV() {
      this.menu.push(
        {
          href: "/operationsverstaendnis",
          title: "Operationsverständnis",
          icon: "mdi mdi-calculator-variant",
          class: "IconColor",
          child: [
            {
              href: "/operationsverstaendnis/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href:
                    "/operationsverstaendnis/hintergrundwissen/grundvorstellungen",
                  title: "Grundvorstellungen besitzen",
                },
                {
                  href:
                    "/operationsverstaendnis/hintergrundwissen/darstellungen",
                  title: "Darstellungen vernetzen",
                },
                {
                  href:
                    "/operationsverstaendnis/hintergrundwissen/aufgabenbeziehungen",
                  title: "Aufgabenbeziehungen nutzen",
                },
              ],
            },
            {
              href: "/operationsverstaendnis/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/operationsverstaendnis/diagnose-und-foerderung",
                  title: "Diagnose & Förderung",
                },
              ],
            },
            {
              href: "/operationsverstaendnis/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },

    addMenuSWV() {
      this.menu.push(
        {
          href: "/stellenwertverstaendnis",
          title: "Stellenwertverständnis",
          icon: "mdi mdi-view-column",
          class: "IconColor",
          child: [
            {
              href: "/stellenwertverstaendnis/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href:
                    "/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln",
                  title: "Bündeln und Entbündeln",
                },
                {
                  href:
                    "/stellenwertverstaendnis/hintergrundwissen/stellenwert-und-zahlenwert",
                  title: "Stellenwert und Zahlenwert",
                },
                {
                  href:
                    "/stellenwertverstaendnis/hintergrundwissen/darstellungswechsel",
                  title: "Darstellungswechsel",
                },
              ],
            },
            {
              href: "/stellenwertverstaendnis/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/stellenwertverstaendnis/diagnose-und-foerderung",
                  title: "Diagnose & Förderung",
                },
              ],
            },
            {
              href: "/stellenwertverstaendnis/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
    addMenuHR() {
      this.menu.push(
        {
          href: "/zahlenrechnen",
          title: "Zahlenrechnen",
          icon: "HR",
          class: "IconColor",
          child: [
            {
              href: "/zahlenrechnen/hintergrundwissen",
              title: "Hintergrundwissen",
              child: [
                {
                  href:
                    "/zahlenrechnen/hintergrundwissen/strategien-des-halbschriftlichen-rechnens",
                  title: "Strategien des Halbschriftlichen Rechnens",
                },
                {
                  href:
                    "/zahlenrechnen/hintergrundwissen/flexibler-einsatz-von-rechenstrategien",
                  title: "Flexibler Einsatz von Rechenstrategien",
                },
                {
                  href:
                    "/zahlenrechnen/hintergrundwissen/darstellungsweisen-beim-halbschriftlichen-rechnen",
                  title: "Darstellungsweisen beim Halbschriftlichen Rechnen",
                },
              ],
            },
            {
              href: "/zahlenrechnen/uebergang",
              title: "Übergang Diagnose",
              child: [
                {
                  href: "/zahlenrechnen/diagnose-und-foerderung",
                  title: "Diagnose & Förderung",
                },
              ],
            },
            {
              href: "/zahlenrechnen/check",
              title: "Kompetenzliste",
            },
          ],
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
    addMenuLogout() {
      this.menu.push(
        {
          href: "/logout",
          title: "Ausloggen",
          icon: "mdi mdi-logout",
          class: "logoutClass",
        },
        {
          component: "hr",
          props: { class: "divider" },
        }
      );
    },
  },
  data() {
    return {
      mini: true,
      menu: [
        {
          component: "hr",
          props: { class: "divider" },
        },
        {
          href: "/home",
          title: "Hauptseite",
          icon: "mdi mdi-home",
        },
        {
          component: "hr",
          props: { class: "divider" },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#app .v-sidebar-menu {
  /* border-right: solid;
  border-width: thin;
  border-color: lightgray; */
  /*box-shadow: 1px 0px 4px -1px rgb(0 0 0 / 20%);*/
  border-right: solid #bbc5d6 2px;
}

#app .v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  height: 40px;
  width: 40px;
}
#app
  .v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1 {
  background-color: #d8f0ee; /*color for picked item while hovering over navbar*/
}

#app .vsm--item_open .IconColor .vsm--title {
  color: #61a2a6; /*color for picked SWV title while hovering over navbar*/
}

#app .router-link-active.vsm--link.vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px #477b7e inset;
  box-shadow: 3px 0px 0px 0px #477b7e inset; /*active group left border next to black icon*/
}

#app .v-sidebar-menu .vsm--link_level-2 {
  padding-left: 60px;
}
#app .v-sidebar-menu .vsm--link_level-3 {
  padding-left: 80px;
}

#app .v-sidebar-menu .vsm--icon {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#app .navigationHeader {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.divider {
  border-color: rgba(0, 0, 0, 0.05);
  border-width: thin 0 0 0;
}

#app
  .v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .IconColor
  .vsm--icon {
  background: #61a2a6; /**backgroundcolor for Icon while picked and sidebar is open */
}

#app .logoutClass .vsm--icon {
  background-color: #477b7e; /** backgroundcolor for logout icon */
}
#app .HR {
  content: url("../assets/HR.svg");
  padding: 5px;
}
#app
  .router-link-active.vsm--link.vsm--link_level-1.vsm--link_active
  .vsm--icon.HR {
  content: url("../assets/HR_w.svg");
}
#app .ZR {
  content: url("../assets/ZR.svg");
  padding: 5px;
}
#app
  .router-link-active.vsm--link.vsm--link_level-1.vsm--link_active
  .vsm--icon.ZR {
  content: url("../assets/ZR_w.svg");
}
</style>
