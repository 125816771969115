<template>
  <div :ref="'topOfSurvey'">
    <v-card color="#477B7E4D" outlined v-if="!send && countSurvey > 1">
      <v-card-text>
        Danke! Sie haben bereits
        <span v-if="countSurvey == 2">
          den Lernkontext Fragebogen
        </span>
        <span v-else>
          die Fragebögen: Lernkontext<span v-if="countSurvey == 3"> und Motivation</span><span v-if="countSurvey == 4">, Motivation und Lernstil</span><span v-if="countSurvey == 5">, Motivation, Lernstil und Gebrauchstauglichkeit</span></span>
          beantwortet.
      </v-card-text>
    </v-card>
    <v-alert
      style="margin-bottom: 12px"
      type="success"
      color="#477B7E"
      text
      v-if="send"
      >Danke für die Beantwortung der Fragebögen!</v-alert
    >
    <v-card outlined v-if="!send && showSurvey" style="margin-bottom: 16px">
      <v-card-text>
        <v-form
          @submit.prevent="ableToSend(countSurvey)"
          id="check-survey-form1"
          v-model="valid[countSurvey - 1]"
          ref="form1"
          lazy-validation
          v-if="countSurvey == 1"
        >
          <h2>Lernkontext</h2>
          <br />
          <p>
            Mit den nächsten Fragen möchten wir herausfinden, wie Ihr größerer
            Lernkontext aussieht und wie Sie beim Lernen vorgehen. Sie werden
            unterschiedliche Aussagen sehen, wählen Sie bitte die Aussagen aus
            die am ehesten Ihre aktuelle Situation beschreiben.
          </p>
          <p>
            Bitte wählen Sie immer eine Antwort aus. Es gibt keine „richtige“
            oder „falsche“ Antwort. Ihre persönliche Einschätzung zählt!
          </p>
          <!-- <SurveyIMMS
            :ref="surveyQuestionsRef"
            v-for="(element, imms_index) in IMMS"
            :id="'imms' + (1 + imms_index)"
            :key="'imms' + imms_index"
            :circleCount="5"
            :description="element.description"
            :valid="valid[countSurvey - 1]"
          /> -->
          <SurveyItemOneChoiceDiss
            :ref="surveyQuestionsRef"
            v-for="(question, questionIndex) in DISS"
            :key="'diss' + questionIndex"
            :id="'diss' + (1 + questionIndex)"
            :question="question.question"
            :answerA="question.answerA"
            :answerACategory="question.answerACategory"
            :answerAText="question.answerAText"
            :answerB="question.answerB"
            :answerBCategory="question.answerBCategory"
            :answerC="question.answerC"
            :answerCCategory="question.answerCCategory"
          >
          </SurveyItemOneChoiceDiss>
          <h2>Zusätzliche Anmerkungen</h2>
          <br />
          <p>
            An dieser Stelle haben Sie die Möglichkeit zusätzliche Anmerkungen
            zu geben. Hier können Sie alles eintragen, was Sie gerne dem FALEDIA
            Forschungsprojekt mitteilen möchten, um die Lernplattform verbessern
            zu können.
          </p>
          <v-textarea
            outlined
            v-model="notes"
            auto-grow
            label="Zusätzliche Anmerkungen (optional)"
            rows="3"
            data-matomo-unmask
            hide-details
          ></v-textarea>
        </v-form>
        <v-form
          @submit.prevent="ableToSend(countSurvey)"
          id="check-survey-form2"
          v-model="valid[countSurvey - 1]"
          ref="form2"
          lazy-validation
          v-if="countSurvey == 2"
        >
          <div v-if="immsflag">
            <h2>Motivation</h2>
            <br />
            <p>
              Mit den nächsten Fragen möchten wir herausfinden, wie motivierend
              Sie FALEDIA empfunden haben. Auf einer Skala von 1 bis 5 können
              Sie die folgenden Aussagen bewerten und entscheiden inwieweit Sie
              ihnen zustimmen.
            </p>
            <p>
              Bitte wählen Sie immer eine Antwort aus. Es gibt keine „richtige“
              oder „falsche“ Antwort. Ihr persönliches Empfinden zählt!
            </p>
            <SurveyIMMS
              :ref="surveyQuestionsRef"
              v-for="(element, imms_index) in IMMS"
              :id="'imms' + (1 + imms_index)"
              :key="'imms' + imms_index"
              :circleCount="5"
              :description="element.description"
              :valid="valid[countSurvey - 1]"
            />
          </div>
          <div v-else>
            <h2>Motivation</h2>
            <br />
            <p>
              Mit den nächsten Fragen möchten wir herausfinden, wie motivierend
              Sie FALEDIA empfunden haben. Auf einer Skala von 1 bis 5 können
              Sie die folgenden Aussagen bewerten und entscheiden inwieweit Sie
              ihnen zustimmen.
            </p>
            <p>
              Bitte wählen Sie immer eine Antwort aus. Es gibt keine „richtige“
              oder „falsche“ Antwort. Ihr persönliches Empfinden zählt!
            </p>
            <SurveyIMMS
              :ref="surveyQuestionsRef"
              v-for="(element, rimms_index) in RIMMS"
              :id="'rimms' + (1 + rimms_index)"
              :key="'rimms' + rimms_index"
              :circleCount="5"
              :description="element.description"
              :valid="valid[countSurvey - 1]"
            />
          </div>
        </v-form>
        <v-form
          @submit.prevent="ableToSend(countSurvey)"
          id="check-survey-form3"
          v-model="valid[countSurvey - 1]"
          ref="form3"
          lazy-validation
          v-if="countSurvey == 3"
        >
          <div v-if="!skipLearningStyle">
            <h2>Ihr persönlicher Lernstil</h2>
            <br />
            <p>
              Im Forschungsprojekt FALEDIA ist eine Forschungsfrage wie gut die
              eingesetzen Lernelemente zu unterschiedlichen Lernstilen passen.
              Es werden Ihnen unterschiedliche Fragen gestellt und dabei sollen
              Sie eine aus zwei Antwortmöglichkeiten wählen, die besser zu Ihnen
              passt.
            </p>
            <p>
              Bitte wählen Sie immer eine Antwort aus. Es gibt keine „richtige“
              oder „falsche“ Antwort. Ihr persönliches Empfinden zählt!
            </p>
            <SurveyItemOneChoice
              :ref="surveyQuestionsRef"
              v-for="(question, felderSolomanIndex) in felderSolomon"
              :key="'felderSoloman' + felderSolomanIndex"
              :id="'felderSoloman' + (1 + felderSolomanIndex)"
              :question="question.question"
              :answerA="question.answerA"
              :answerACategory="question.answerACategory"
              :answerB="question.answerB"
              :answerBCategory="question.answerBCategory"
            />
          </div>
        </v-form>
        <v-form
          @submit.prevent="ableToSend(countSurvey)"
          id="check-survey-form4"
          v-model="valid[countSurvey - 1]"
          ref="form4"
          lazy-validation
          v-if="countSurvey == 4"
        >
          <h2>
            Gebrauchstauglichkeit (Usability)
          </h2>
          <br />
          <p>
            Um FALEDIA zu bewerten, füllen Sie bitten den nachfolgenden
            Fragebogen aus. Er besteht aus Gegensatzpaaren von Eigenschaften,
            die FALEDIA haben kann. Abstufungen zwischen den Gegensätzen sind
            durch Kreise dargestellt. Durch Anklicken eines dieser Kreise können
            Sie Ihre Zustimmung zu einem Begriff äußern.
          </p>
          <p>
            Entscheiden Sie möglichst spontan. Es ist wichtig, dass Sie nicht
            lange über die Begriffe nachdenken, damit Ihre unmittelbare
            Einschätzung zum Tragen kommt.
          </p>
          <p>
            Bitte wählen Sie immer eine Antwort aus, auch wenn Sie bei der
            Einschätzung zu einem Begriffspaar unsicher sind oder finden, dass
            es nicht so gut zu FALEDIA passt. Es gibt keine „richtige“ oder
            „falsche“ Antwort. Ihre persönliche Meinung zählt!
          </p>
          <br />
          <v-row no-gutters>
            <SurveyItem
              :ref="surveyQuestionsRef"
              v-for="(element, ueq_index) in ueq"
              :id="'ueq' + (1 + ueq_index)"
              :key="'ueq' + ueq_index"
              :circleCount="7"
              :left="element.left"
              :right="element.right"
              :valid="valid[countSurvey - 1]"
            />
          </v-row>
        </v-form>
        <v-form
          @submit.prevent="ableToSend(countSurvey)"
          id="check-survey-form5"
          v-model="valid[countSurvey - 1]"
          ref="form5"
          lazy-validation
          v-if="countSurvey == 5"
          ><h2>Anforderung an den Körper</h2>
          <br />
          <p>
            An dieser Stelle möchten wir erfahren, wie Sie die Anforderungen an
            Ihrem Körper empfunden haben. Auf einer Skala von gering bis hoch
            können Sie jeweils für die Kategorien Ihre Empfindungen eintragen.
            Ein Strich zeigt Ihnen dabei die Mitte der Skala an.
          </p>
          <p>
            Bitte wählen Sie immer eine Antwort aus. Es gibt keine „richtige“
            oder „falsche“ Antwort. Ihr persönliches Empfinden zählt!
          </p>
          <SurveyItemNasa
            :ref="surveyQuestionsRef"
            v-for="(element, nasa_index) in nasa"
            :id="'nasa' + (1 + nasa_index)"
            :key="'nasa' + nasa_index"
            :circleCount="20"
            :borderAfter="10"
            :text="element.text"
            :left="element.left"
            :right="element.right"
            :description="element.description"
            :valid="valid[countSurvey - 1]"
          />
        </v-form>
        <v-alert type="error" text v-if="!valid[countSurvey - 1] && err">{{
          err
        }}</v-alert>
        <!-- <v-alert type="success" text v-if="success">{{ success }}</v-alert> -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <p class="surveyNumber">
          {{ countSurvey }} / {{ availableSurveys.length }}
        </p>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid[countSurvey - 1] || !sendable"
          :form="'check-survey-form' + countSurvey"
          type="submit"
          color="#477B7E"
          ><div
            style="color: white;"
            v-if="countSurvey === availableSurveys.length"
          >
            <v-icon>mdi-send</v-icon> Abschicken
          </div>
          <div style="color: white;" v-else>
            Abschicken & nächste Seite
            <v-icon>mdi-arrow-right-bold-outline</v-icon>
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import SurveyItem from "./SurveyItem";
import SurveyItemOneChoice from "./SurveyItemOneChoice";
import SurveyItemOneChoiceDiss from "./SurveyItemOneChoiceDiss";
import SurveyItemNasa from "./SurveyItemNasa";
import AppHelper from "@/common/AppHelper";
import SurveyIMMS from "@/components/faledia/SurveyIMMS";

export default {
  components: {
    SurveyItem,
    SurveyItemOneChoice,
    SurveyItemNasa,
    SurveyIMMS,
    SurveyItemOneChoiceDiss,
  },
  props: {
    //assignedSite: { String, required: true }, here it's always home
    alreadySend: { Boolean },
    skipLearningStyle: { Boolean },
  },
  data() {
    return {
      sendable: true,
      availableSurveys: ["diss", "imms", "soloman", "ueq", "nasa"], //surveys displayed
      value: null,
      showSurvey: true,
      valid: [true, true, true, true, true],
      err: "",
      success: "",
      surveyQuestionsRef: "surveyQuestionsRef",
      answers: [],
      notes: "",
      immsflag: false, //flag for (r)imms survey, if true = imms, if false = rimms
      countSurvey: 1, //the displayed survey
      ueq: [
        { left: "behindernd", right: "unterstützend" },
        { left: "kompliziert", right: "einfach" },
        { left: "ineffizient", right: "effizient" },
        { left: "verwirrend", right: "übersichtlich" },
        { left: "langweilig", right: "spannend" },
        { left: "uninteressant", right: "interessant" },
        { left: "konventionell", right: "originell" },
        { left: "herkömmlich", right: "neuartig" },
      ],

      nasa: [
        {
          text: "Geistige Anforderung",
          left: "gering",
          right: "hoch",
          description:
            "Wie viel geistige Anforderung war bei der Informationsaufnahme und bei der Informationsverarbeitung während der Nutzung der Lernplattform erforderlich (z.B. Denken, Entscheiden, Rechnen, Erinnern, Hinsehen, Suchen ...)?",
        },
        {
          text: "Zeitliche Anforderung",
          left: "gering",
          right: "hoch",
          description:
            "Wie viel Zeitdruck empfanden Sie hinsichtlich der Häufigkeit oder dem Takt mit dem die Aufgaben oder Aufgabenelemente auftraten? War die Aufgabe langsam und geruhsam oder schnell und hektisch?",
        },
        {
          text: "Leistung",
          left: "gering",
          right: "hoch",
          description:
            "Wie erfolgreich haben Sie Ihrer Meinung nach die von den Dozent:innen (oder Ihnen selbst) gesetzten Ziele erreicht? Wie zufrieden waren Sie mit Ihrer Leistung bei der Verfolgung dieser Ziele?",
        },
        {
          text: "Anstrengung",
          left: "gering",
          right: "hoch",
          description:
            "Wie hart mussten Sie arbeiten, um Ihren Grad an Aufgabenerfüllung für die Abgabe Ihrer Lösung zu erreichen?",
        },
        {
          text: "Frustration",
          left: "gering",
          right: "hoch",
          description:
            "Wie unsicher, entmutigt, irritiert, gestresst und verärgert (versus sicher, bestätigt, zufrieden, entspannt und zufrieden mit sich selbst) fühlten Sie sich während der Nutzung der Lernplattform?",
        },
      ],

      // reduziert durch graf
      felderSolomon: [
        {
          question: "Ich gelte eher als ",
          answerA: "kontaktfreudig.",
          answerACategory: "aktiv",
          answerB: "zurückhaltend.",
          answerBCategory: "reflektiv",
        },
        {
          question:
            "Wenn ich eine lehrende Person wäre, würde ich lieber einen Kurs geben, ",
          answerA: "der sich mit Fakten und realen Situationen beschäftigt.",
          answerACategory: "sensorisch",
          answerB: "der sich mit Ideen und Theorien beschäftigt.",
          answerBCategory: "intuitiv",
        },
        {
          question: "Wenn mir Daten gezeigt werden, bevorzuge ich ",
          answerA: "Diagramme und Graphen.",
          answerACategory: "visuell", // hier fehlte die Kategorie
          answerB: "eine textliche Zusammenfassung der Ergebnisse.",
          answerBCategory: "verbal",
        },
        {
          question: "Wenn ich mich in ein neues Fachgebiet einarbeite, ",
          answerA:
            "konzentriere ich mich ganz auf dieses Fach und lerne so viel ich kann.",
          answerACategory: "sequenziell",
          answerB: "versuche ich Verbindungen zu anderen Fächern herzustellen.",
          answerBCategory: "global",
        },
        {
          question: "Ich verstehe eine Sache besser, wenn ich sie ",
          answerA: "ausprobiere.",
          answerACategory: "aktiv",
          answerB: "durchdenke.",
          answerBCategory: "reflektiv",
        },
        {
          question: "Ich bevorzuge Kurse, die ",
          answerA: "konkrete Inhalte (Fakten, Daten) behandeln.",
          answerACategory: "sensorisch",
          answerB: "abstrakte Inhalte (Konzepte, Theorien) behandeln.",
          answerBCategory: "intuitiv",
        },
        {
          question:
            "In einem Buch mit vielen Bildern und Diagrammen konzentriere ich mich eher auf",
          answerA: "die genaue Betrachtung der Bilder und Diagramme.",
          answerACategory: "visuell",
          answerB: "das Lesen der Textbeschreibungen.",
          answerBCategory: "verbal",
        },

        {
          question: "Für mich ist es wichtiger, dass die lehrende Person ",
          answerA: "den Inhalt in klaren, fortlaufenden Schritten vermittelt.",
          answerACategory: "sequenziell",
          answerB:
            "einen allgemeinen Überblick liefert und Bezüge zu anderen relevanten Themenfeldern herstellt.",
          answerBCategory: "global",
        },
        {
          question: "Wenn ich an einem Kurs teilgenommen habe, habe ich ",
          answerA: "meist viele Kommilitonen kennengelernt.",
          answerACategory: "aktiv",
          answerB: "selten viele Kommilitonen kennengelernt.",
          answerBCategory: "reflektiv",
        },

        {
          question: "Ich bevorzuge den Gedanken an die Beschäftigung mit ",
          answerA: "Tatsachen.",
          answerACategory: "sensorisch",
          answerB: "Theorien.",
          answerBCategory: "intuitiv",
        },
        {
          question:
            "Mir ist es lieber, wenn mir neue Informationen vermittelt werden mittels ",
          answerA: "Bildern, Diagrammen, Graphen und Karten.",
          answerACategory: "visuell",
          answerB: "Textanleitungen oder mündlichen Vorträgen.",
          answerBCategory: "verbal",
        },
        {
          question: "Wenn ich einmal verstanden habe, ",
          answerA:
            "was die einzelnen Komponenten eines Themas bedeuten, verstehe ich den gesamten Komplex.",
          answerACategory: "sequenziell",
          answerB:
            "was den Komplex eines Themas umfasst, erkenne ich, wie die einzelnen Komponenten zusammenpassen.",
          answerBCategory: "global",
        },
      ],
      IMMS: [
        {
          description:
            "Als ich die Lernplattform zum ersten Mal angesehen habe, hatte ich den Eindruck, dass mir der Umgang mit ihr leichtfallen wird.",
        },
        {
          description:
            "Nach dem Einloggen auf der Lernplattform gab es etwas Interessantes, das meine Aufmerksamkeit erregte.",
        },
        {
          description:
            "Der Umgang mit der Lernplattform war schwieriger zu verstehen, als ich es mir gewünscht hätte.",
        },
        {
          description:
            "Nachdem ich die einleitenden Informationen gelesen hatte, war ich sicher, dass ich wusste, was ich durch die Nutzung der Lernplattform lernen sollte.",
        },
        {
          description:
            "Das Abschließen der Übungen auf der Lernplattform gab mir ein zufriedenstellendes Erfolgsgefühl.",
        },
        {
          description:
            "Es ist mir klar, wie der Inhalt der Lernplattform mit Dingen zusammenhängt, die ich bereits weiß.",
        },
        {
          description:
            "Die Seiten der Lernplattform enthielten so viele Informationen, dass es schwierig war, die wichtigen Punkte herauszufiltern und sich zu merken.",
        },
        {
          description: "Die Lernplattform ist Aufmerksamkeitserregend.",
        },
        {
          description:
            "Es gab Texte, Bilder oder Beispiele, dir mir zeigten, wie wichtig der Inhalt der Lernplattform für manche Personen sein könnte.",
        },
        {
          description:
            "Es war mir wichtig, die Inhalte der Lernplattform erfolgreich durchzuarbeiten.",
        },
        {
          description:
            "Die Qualität der Inhalte auf der Lernplattform trug dazu bei, meine Aufmerksamkeit aufrecht zu halten.",
        },
        {
          description:
            "Die Inhalte der Lernplattform sind so abstrakt, dass es mir schwerfiel, mich auf sie zu konzentrieren.",
        },
        {
          description:
            "Als ich die Lernplattform genutzt habe, war ich zuversichtlich, dass ich den Inhalt lernen könnte.",
        },
        {
          description:
            "Der Inhalt der Lernplattform hat mir so gut gefallen, dass ich gerne mehr über die Themen erfahren möchte.",
        },
        {
          description:
            "Die Lernplattform sieht langweilig und unattraktiv aus.",
        },
        {
          description:
            "Der Inhalt der Lernplattform ist für mich von Interesse.",
        },
        {
          description:
            "Die Art und Weise, wie die Inhalte auf den Seiten der Lernplattform angeordnet sind, hat dazu beigetragen meine Aufmerksamkeit aufrecht zu halten.",
        },
        {
          description:
            "Es gibt Erklärungen oder Beispiele wie Personen den Inhalt dieser Lernplattform nutzen.",
        },
        {
          description: "Die Übungen auf der Lernplattform waren zu schwierig.",
        },
        {
          description:
            "Die Lernplattform enthält Elemente, die meine Neugierde geweckt haben.",
        },
        {
          description: "Mir hat es gefallen die Lernplattform zu nutzen.",
        },
        {
          description:
            "Die Menge an Wiederholungen auf der Lernplattform haben mich manchmal gelangweilt.",
        },
        {
          description:
            "Der Inhalt und der Schreibstil auf der Lernplattform vermitteln den Eindruck, dass der Inhalt wissenswert ist.",
        },
        {
          description:
            "Ich habe manche Dinge gelernt, die überraschend oder unerwartet waren.",
        },
        {
          description:
            "Nachdem ich die Lernplattform, eine Weile, genutzt hatte, war ich zuversichtlich, dass ich eine Prüfung über den Inhalt bestehen würde.",
        },
        {
          description:
            "Die Nutzung der Lernplattform war für mich nicht nötig, weil ich die meisten Inhalte bereits kannte.",
        },
        {
          description:
            "Die Formulierung des Feedbacks in den Übungen, auf der Lernplattform, hat mir das Gefühl gegeben, für meine Bemühungen belohnt zu werden.",
        },
        {
          description:
            "Die Vielfalt der Texte, Übungen, Bilder, etc. hat mir geholfen meine Aufmerksamkeit aufrechtzuhalten.",
        },
        {
          description: "Der Schreibstil ist langweilig.",
        },
        {
          description:
            "Ich konnte den Inhalt der Lernplattform auf Dinge beziehen, die ich in meinem eigenen Leben gesehen, getan oder über die ich nachgedacht habe.",
        },
        {
          description:
            "Auf jeder Seite der Lernplattform sind so viele Wörter, dass es irritierend ist.",
        },
        {
          description:
            "Es gibt mir ein gutes Gefühl, die Arbeit mit der Lernplattform erfolgreich abzuschließen.",
        },
        {
          description:
            "Der Inhalt der Lernplattform wird für mich von Nutzen sein.",
        },
        {
          description:
            "Ich konnte wesentliche Teile des Inhaltes der Lernplattform nicht wirklich verstehen.",
        },
        {
          description:
            "Die gute Gliederung des Inhalts der Lernplattform hat mir geholfen, mich darauf zu verlassen, dass ich den Stoff lernen werde.",
        },
        {
          description:
            "Es war ein Vergnügen, an einer so gut konzipierten Lernplattform zu arbeiten.",
        },
      ],
      RIMMS: [
        {
          description:
            "Es ist mir klar, wie der Inhalt der Lernplattform mit Dingen zusammenhängt, die ich bereits weiß.",
        },
        {
          description:
            "Die Qualität der Inhalte auf der Lernplattform trug dazu bei, meine Aufmerksamkeit aufrecht zu halten.",
        },
        {
          description:
            "Als ich die Lernplattform genutzt habe, war ich zuversichtlich, dass ich den Inhalt lernen könnte.",
        },
        {
          description:
            "Der Inhalt der Lernplattform hat mir so gut gefallen, dass ich gerne mehr über die Themen erfahren möchte.",
        },
        {
          description:
            "Die Art und Weise, wie die Inhalte auf den Seiten der Lernplattform angeordnet sind, hat dazu beigetragen meine Aufmerksamkeit aufrecht zu halten.",
        },
        {
          description: "Mir hat es gefallen die Lernplattform zu nutzen.",
        },
        {
          description:
            "Der Inhalt und der Schreibstil auf der Lernplattform vermitteln den Eindruck, dass der Inhalt wissenswert ist.",
        },
        {
          description:
            "Nachdem ich die Lernplattform, eine Weile, genutzt hatte, war ich zuversichtlich, dass ich eine Prüfung über den Inhalt bestehen würde.",
        },
        {
          description:
            "Die Vielfalt der Texte, Übungen, Bilder, etc. hat mir geholfen meine Aufmerksamkeit aufrechtzuhalten.",
        },
        {
          description:
            "Der Inhalt der Lernplattform wird für mich von Nutzen sein.",
        },
        {
          description:
            "Die gute Gliederung des Inhalts der Lernplattform hat mir geholfen, mich darauf zu verlassen, dass ich den Stoff lernen werde.",
        },
        {
          description:
            "Es war ein Vergnügen, an einer so gut konzipierten Lernplattform zu arbeiten.",
        },
      ],

      DISS: [
        // mir ist bis jetzt kein besserer Name eingefallen. Damit ist der Fragebogen gemeint der aus dem Kontext von Andreas' Diss entstanden ist.
        {
          question: "1. Aussage",
          answerA:
            "Ich lerne in der Regel im selben Raum / am selben Ort. (Lernumgebung)",
          answerB:
            "Ich lerne in der Regel im selben Raum / am selben Ort und schlafe auch in diesem Raum. (Lernumgebung)",
          answerC:
            "Meine Lernumgebung wechselt regelmäßig zwischen mehreren Orten.",
        },
        {
          question: "2. Aussage",
          answerA:
            "In meiner Lernumgebung kann ich in der Regel ungestört lernen.",
          answerB: "In meiner Lernumgebung werde ich regelmäßig gestört.",
        },
        {
          question: "3. Aussage",
          answerA:
            "Meine Lernumgebung ist der aktuellen Coronasituation geschuldet und würde sonst folgendermaßen aussehen:", // hier Freitext anbieten
          answerAText: true,
          answerB:
            "Meine Lernumgebung würde unabhängig von der aktuellen Coronasituation sehr ähnlich aussehen.",
        },
        {
          question: "4. Aussage",
          answerA:
            "Ich lerne im Rahmen eines vorher geplanten Zeitraums, z.B. in Form fester Uni-Tage.",
          answerB:
            "Ich lerne wenn ich Gelegenheit dazu habe ohne das vorher zu planen.",
          answerC: "Ich lerne zum letztmöglichen Zeitpunkt.",
        },
        {
          question: "5. Aussage",
          answerA:
            "Ich habe mir vor dem Lernen Ziele gesetzt die ich erfüllen möchte.",
          answerB: "Ich habe mir vor dem Lernen keine Ziele gesetzt.",
        },
        {
          question: "6. Aussage",
          answerA:
            "Ich habe das Gefühl für meinen Lernerfolg verantwortlich zu sein.",
          answerB:
            "Andere Personen (wie z.B. Dozent:innen) sind auch für meinen Lernerfolg verantwortlich.",
        },
        {
          question: "7. Aussage",
          answerA: "Ich belohne mich nach dem Lernen.",
          answerB: "Ich belohne mich nicht nach dem Lernen.",
        },
        {
          question: "8. Aussage",
          answerA:
            "Wenn ich lerne nutze ich die eingeplante Zeit effektiv und fokussiere mich.",
          answerB: "Wenn ich lerne lasse ich mich leicht ablenken.",
        },
        {
          question: "9. Aussage",
          answerA: "Die gelernten Inhalte bespreche ich mit anderen Personen.",
          answerB:
            "Ich würde gerne die Inhalte mit anderen Personen besprechen, habe aber keine Ansprechpersonen.",
          answerC:
            "Die gelernten Inhalte bespreche ich nicht mit anderen Personen.",
        },
        {
          question: "10. Aussage",
          answerA:
            "Ich übertrage Inhalte 1 zu 1 in ein anderes Dokument (z.B. Lerntagebuch, Notizen, oder ähnliches).",
          answerB:
            "Ich übertrage Inhalte mit eigenen Formulierungen in ein anderes Dokument (z.B. Lerntagebuch, Notizen, oder ähnliches).",
          answerC: "Ich übertrage Inhalte nicht in ein anderes Dokument.",
        },
        {
          question:
            "11. Aussage: Sie wurden im Rahmen der Vorlesung auf die Lernplattform geschickt. Wie waren Ihre Erwartungen?",
          answerA:
            "Ich habe mich gefreut mich mit den Inhalten auseinander zu setzen.",
          answerB:
            "Ich habe mich mit den Inhalten aus einem Pflichtgefühl heraus auseinander gesetzt.",
        },
        {
          question: "12. Aussage",
          answerA:
            "Die Lernplattform hat mich bei meinem Ziel unterstützt eine gute Lehrkraft zu werden.",
          answerB:
            "Die Lernplattform hat mich nicht ausreichend bei meinem Ziel unterstützt eine gute Lehrkraft zu werden.",
        },
      ],

      send: false, //this.alreadySend,
    };
  },

  mounted: function() {
    let user = this.$store.state.user;
    if (user.surveyKind === "imms") {
      this.immsflag = true;
    } else {
      this.immsflag = false;
    }

    // how many surveys were already completed
    this.countSurvey = user.completedSurveys.length + 1; // we have to add the 1 because it's 1 based and not 0 based

    // are we finished?
    if (user.completedSurveys.length == this.availableSurveys.length) {
      // if the user has as many completed surveys as assigned surveys she did all the surveys and send is true
      this.send = true;
    }
  },
  methods: {
    async ableToSend(formNumber) {
      this.sendable = false;
      await this.sendSurvey(formNumber);
      this.sendable = true;
    },
    async sendSurvey(formNumber) {
      // Diese Stelle könnte man verbessern in dem man die Referenz des forms übergibt und darauf weiterarbeitet, dann würde man sich die Abfrage mit den magic numbers sparen
      if (formNumber == 1) {
        this.valid[formNumber] = this.$refs.form1.validate();
      } else if (formNumber == 2) {
        this.valid[formNumber] = this.$refs.form2.validate();
      } else if (formNumber == 3) {
        this.valid[formNumber] = this.$refs.form3.validate();
      } else if (formNumber == 4) {
        this.valid[formNumber] = this.$refs.form4.validate();
      } else if (formNumber == 5) {
        this.valid[formNumber] = this.$refs.form5.validate();
      }
      if (!this.valid[formNumber]) {
        this.err =
          "Es wurden nicht alle Elemente ausgewählt. Die nicht ausgewählten Elemente sind rot markiert.";
        AppHelper.trackMatomoEvent(
          this,
          "Survey",
          "id:53; Abschicken gedrückt; Umfrage enthielt nicht ausgefüllte Felder;",
          this.availableSurveys[formNumber - 1]
        );
      }
      if (this.valid[formNumber]) {
        this.err = "";
        for (let i = 0; i < this.$refs.surveyQuestionsRef.length; i++) {
          this.answers.push(this.$refs.surveyQuestionsRef[i].getData());
        }

        if (formNumber == 1 && this.notes) {
          // keine schöne lösung, wir fügen die notes, falls vorhanden, nur an den ersten Fragebogen
          // wenn die fragebögen in einem durch gemacht werden, erhält jeder fragebogen ansonsten am ende die notes
          // alternativ hätte man auch die notes löschen können
          this.answers.push({ notes: this.notes });
        }

        const token = localStorage.getItem("jwt");
        let surveyData = {
          assignedSite: this.availableSurveys[formNumber - 1],
          surveyData: this.answers,
        };
        try {
          let that = this;
          await this.$http
            .post("/user/survey", surveyData, {
              headers: {
                Authorization: token,
              },
            })
            .then((response) => {
              if (formNumber == 5) {
                this.success = "Danke für die Teilnahme an der Umfrage";
                this.send = true;
              }
              this.err = "";
              localStorage.setItem("jwt", response.data.token);
              this.answers.splice(0); // remove items from answer array so we don't send them again
              // this.$router.push("/home/" + isEve);
              AppHelper.trackMatomoEvent(
                this,
                "Survey",
                "id:52; Abschicken gedrückt; Umfrage erfolgreich abgeschickt;",
                surveyData.assignedSite
              );
              this.countSurvey++;
              that.$scrollTo(that.$refs["topOfSurvey"]);
            })
            .catch((err) => {
              this.succes = "";
              this.err =
                err.response.status + ": " + err.response.data.err.message;
            });
        } catch (err) {
          this.succes = "";
          if(err){
            this.err = err.response.status + ": " + err.response.data.err.message;
          }
        }
      }
    },
    openSurvey() {
      this.showSurvey = true;
      AppHelper.trackMatomoEvent(
        this,
        "Survey",
        "id:54; Umfrage geöffnet;",
        this.assignedSite
      );
    },
  },
};
</script>
<style scoped>
* {
  color: black;
}
.moreCompact {
  margin-top: 0px;
  padding-top: 0px;
}
.surveyNumber {
  color: #477b7e;
  font-size: large;
  margin: auto;
  padding-left: 8px;
}
</style>
