<template>
  <v-app>
    <AppNavLeft v-if="!hideNavigation" />
    <div class="greyBackground"></div>
    <v-main style="padding-left: 60px;">
      <!-- if we are logged in, we add padding for the left navigation -->
      <!-- the AppNavLeft doesn't use the vuetify navigation drawer, so we have to set the offset by hand.  -->
      <v-container fluid style="padding-bottom: 100px;">
        <v-row justify="center">
          <v-col lg="8" md="11" sm="11">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppNavLeft from "./common/AppNavLeft";

export default {
  name: "App",

  computed: {
    hideNavigation: function() {
      if (this.$route.meta.hideNavigationForLoggedOutUser) {
        return this.$store.state.user ? false : true;
      }
      return this.$route.meta.hideNavigation;
    },
  },

  components: {
    AppNavLeft,
  },
};
</script>

<style>
.lbst-border {
  border: 4px solid gray;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
  /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12); */
}
video.lbst-border {
  padding: 0px;
}
/* set the default text color in v-card components to black, otherwise it is greyish */
#app .v-card__subtitle,
#app .v-card__text,
#app .v-card__title {
  color: black;
  font-size: 16px; /* v-cards have a font-size of 0.875rem, we override that with 16px to match the overall font-size */
}
#app * {
  word-break: keep-all;
}
.faledia-caption {
  font-weight: normal;
  padding-bottom: 8px;
}
.colorOV {
  background-color: #61a2a6;
}
.colorSWV {
  background-color: #61a2a6;
}
#app .backgroundSWV {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-color: #61a2a6;
  filter: grayscale(30%);
}
#app .backgroundOV {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-color: #61a2a6;
  filter: grayscale(30%);
}
#app .greyBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-color: #f5f6f6;
}
#app .greyColor {
  background-color: #f5f6f6;
}

#app .darkPetrolBackground {
  background-color: #477b7e;
}

#app .darkPetrol {
  color: #477b7e;
}
#LBST_HR_Stempeln_Bsp1_Bild::after {
  content: url("assets/hr/Stempeln/Bsp1/02.png");
  width: 30%;
}
div#LBST_HR_Stempeln_Bsp1_Bild {
  max-width: 30%;
}
</style>
