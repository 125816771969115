import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex);
// https://vuex.vuejs.org/guide/mutations.html use constants?
const store = new Vuex.Store({
    state: {
        user: null,
        falediaState: null,
        matomoEventCounter: 0,
    },
    modules: {
        nested: {
            namespaced: true
        }
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        falediaState(state, falediaState) {
            state.falediaState = falediaState;
        },
        incrementMatomoEventCounter(state) {
            state.matomoEventCounter++;
        }
    }
});

export default store;