<template>
  <v-card light :width="width">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-alert text :type="type">{{ message }}</v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="primary" class="mx-auto" @click="close">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import eventBus from "./EventBus";
export default {
  props: {
    type: String,
    message: String,
    title: String,
    width: {Number, default: 500},
  },

  methods: {
    close() {
      eventBus.$emit("alertcard-close");
    },
  },
};
</script>
