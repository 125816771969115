<template>
  <div>
    <v-radio-group
      class="moreCompact"
      :rules="[this.value != null || '']"
      dense
      column
      v-model="value"
      @change="logChangeEvent"
    >
      {{ question }}
      <v-radio value="A" :label="answerA" class="underneath">
        <template v-slot:label>
          <div>{{ answerA }}</div>
          <v-textarea
            v-if="answerAText"
            :rules="value == 'A' ? [(v) => !!(v || '')] : []"
            style="margin-top: 4px"
            outlined
            v-model="additionalText"
            auto-grow
            label="Beschreibung Lernumgebung"
            rows="3"
            data-matomo-unmask
            hide-details
          ></v-textarea>
        </template>
      </v-radio>
      <v-radio value="B" :label="answerB">
        <template v-slot:label>
          <div>{{ answerB }}</div>
        </template>
      </v-radio>
      <v-radio v-if="answerC" value="C" :label="answerC">
        <template v-slot:label>
          <div>{{ answerC }}</div>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  props: {
    id: String,
    question: String,
    answerA: String,
    answerAText: Boolean, // a v-textarea appears and users can input text, currently only for answer a
    answerB: String,
    answerC: String,
  },
  data() {
    return {
      value: null,
      additionalText: null,
    };
  },
  methods: {
    getData() {
      let object = {};
      object["id"] = this.id;
      object["value"] = this.value;
      object["additionalText"] = this.value == "A" ? this.additionalText : null; // :( ugly, we only set the added additionalText if we select answer A, but gut enough for now
      return object;
    },
    logChangeEvent() {
      AppHelper.trackMatomoEvent(
        this,
        "Survey",
        "id:51; Value: " + this.value + ";",
        this.id
      );
    },
  },
};
</script>
<style scoped>
* {
  color: black;
}
#app .moreCompact {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
<style>
#app
  .v-input--selection-controls
  .underneath
  > .v-input--selection-controls__input {
  align-self: flex-start;
}

#app .v-input--selection-controls .underneath > .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  display: block;
}
</style>
