<template>
  <div>
    <v-img src="@/assets/faledia_logo.svg" width="150" height="67" class="mx-auto mt-5"></v-img>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="loginUser"
          id="check-login-form"
          v-model="valid"
          ref="form"
        >
          <v-text-field
            id="email"
            type="email"
            label="E-Mail"
            prepend-icon="mdi-account-circle"
            v-model="login.email"
            :rules="emailRules"
          />
          <v-text-field
            id="password"
            type="password"
            label="Passwort"
            prepend-icon="mdi-lock"
            v-model="login.password"
            :rules="passwordRules"
          />
        </v-form>
        <v-alert type="error" text v-if="err" color="primary">
          {{ err }}
          <br />
          <br />
          <passwortVergessen style="margin-left: -4%;" />
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!-- <v-btn small text color="secondary" @click="overlay = !overlay"
          >Passwort vergessen?</v-btn
        > -->
        <v-btn x-small elevation="0" @click="err = 'Passwort anfragen'">
          Passwort vergessen?
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          id="btn_submit"
          form="check-login-form"
          type="submit"
          color="primary"
          ><v-icon>mdi-login</v-icon> Anmelden</v-btn
        >

        <!-- OverlayDialog für Passwort Vergessen Formular -->
        <v-overlay :value="overlay" :opacity="0.5">
          <passwortVergessen />
        </v-overlay>
      </v-card-actions>
    </v-card>
    <br />
    <v-card width="400" class="mx-auto mt-5">
      <v-card-text style="color: grey;"
        >Noch kein Account vorhanden?</v-card-text
      >
      <v-divider></v-divider>
      <v-card-actions v-show="!newAccount">
        <v-spacer></v-spacer>
        <v-btn style="font-size: smaller;" @click="newAccount = true"
          >Account erstellen <v-icon>mdi-login-variant</v-icon></v-btn
        >
      </v-card-actions>
      <div v-show="newAccount && !newAccountCreated" style="padding: 15px;">
        <v-card-text>
          Bitte geben Sie Ihre E-Mail-Adresse an
        </v-card-text>
        <v-form
          id="create-new-account-form"
          v-model="validNew"
          @submit.prevent="createNewAccount"
        >
          <v-text-field
            label="E-Mail"
            prepend-icon="mdi-account-circle"
            v-model="newAccountEmail"
            :rules="emailRules"
          />
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            style="font-size: smaller;"
            :disabled="!validNew"
            form="create-new-account-form"
            color="primary"
            >Registrieren <v-icon>mdi-login-variant</v-icon></v-btn
          >
        </v-card-actions>
      </div>
      <v-card-text v-show="newAccountCreated">
        Ihr Account wurde erfolgreich erstellt. Sie können sich jetzt einloggen.
        Das Passwort wurde Ihnen per E-Mail zugeschickt.
      </v-card-text>
    </v-card>

    <!-- <br />
    <v-divider />
    <br />
    <v-row justify="center">
      <v-col :cols="12" style="text-align: center;"
        ><span
          >ACHTUNG! Der untere Bereich ist nur für Testzwecke gedacht! Er wird
          nicht auf der späteren Seite zu sehen sein.</span
        ></v-col
      >
      <v-col :cols="12">
        <DebugAccountCreator />
      </v-col>
    </v-row> -->
    <AppFooter />
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import eventBus from "../../common/EventBus";
import passwortVergessen from "./PasswortVergessen";
// import DebugAccountCreator from "./DebugAccountCreator";
import AppFooter from "@/common/AppFooter";

export default {
  components: {
    passwortVergessen,
    AppFooter,
    // DebugAccountCreator,
  },
  data() {
    return {
      newAccountCreated: false,
      newAccount: false,
      validNew: true,
      newAccountEmail: "",
      login: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || "E-Mail wird benötigt",
        (v) => /.+@.+\..+/.test(v) || "E-Mail muss valide sein",
      ],
      passwordRules: [(v) => !!v || "Password wird benötigt"],
      valid: true,
      overlay: false,
      err: "",
    };
  },
  methods: {
    async createNewAccount() {
      try {
        this.err = "";
        this.login.email = this.newAccountEmail;
        const response = await this.$http.post(
          "/user/createNewAccount",
          this.login
        );
        const data = response.data.data;
        if (data) {
          this.newAccountCreated = true;
        }
      } catch (err) {
        console.log(err.response);
        this.err = err.response.status + ": " + err.response.data.err;
      }
    },
    async loginUser() {
      this.err = null;
      try {
        const response = await this.$http.post("/user/login", this.login);
        const token = response.data.token;
        const falediaState = response.data.state; // we had to split the state from the token, otherwise the token would get to big and cause problems with axios (network error)
        localStorage.setItem("jwt", token);

        this.$store.commit("user", jwt_decode(token)); // we get a new token, why not use it?
        if (falediaState != null) {
          this.$store.commit("falediaState", falediaState); // we just can save the state in our store, localstorage is only key value
        }

        let user = jwt_decode(token);

        if (token) {
          // we have a token, so the login was successfull
          if (user.eve == null || user.eve === false) {
            // if we didn't answer eve or we said false, we display it again ;)
            this.$router.push("/eve");
          } else {
            this.$router.push("/home");
          }
        }
      } catch (err) {
        console.log(err.response);
        this.err = err.response.status + ": " + err.response.data.err;
      }
    },
  },
  created() {
    eventBus.$on("close", () => {
      this.overlay = false;
      this.err = null;
    });
  },
  mounted() {
    //ToDo: we forward if there is a jwt token ... no checking, nothing ...
    // ... not completely true, the check is in the router before each method
    if (localStorage.getItem("jwt") != null) {
      this.$router.push("/home");
    }
  },
};
</script>

<style scoped></style>
